@mixin phone {
    @media only screen and (max-width: 450px) {
        @content;
    }
}

@mixin extrasmall {
    @media only screen and (max-width: 576px) {
        @content;
    }
}

@mixin only_extrasmall {
    @media only screen and (max-width: 576px) {
        @content;
    }
}

@mixin small {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin only-tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin only-desktop {
    @media only screen and (max-width: 992px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin medium {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin max-large {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin small-plus {
    @media only screen and (max-width: 500px) {
        @content;
    }
}

.scroller {
    background: transparent;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-color: rgb(2 215 220 / 42%) transparent;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(2 215 220 / 42%);
    }
}