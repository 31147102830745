@font-face {
    font-family: 'Open Sans Regular';
    src: url('/assets/fonts/Open_Sans/open_sans_regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url('/assets/fonts/Open_Sans/open_sans_italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('/assets/fonts/Open_Sans/open_sans_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans Bold Itailc';
    src: url('/assets/fonts/Open_Sans/open_sans_bold_italic.ttf') format('truetype');
}