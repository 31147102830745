@import './assets/fonts/Roboto.scss';
@import './assets/fonts/Open_Sans/Open_Sans.scss';
@import './assets/fonts/Nunito/Nunito.scss';

@import "./styles.enviroments.scss";

@import "./material.styles.scss";

@import "./mixins.scss";

// import our custom theme
@import './theme.scss';

html,
body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

[hidden] {
  display: none !important;
}

.flex-left {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #F5F5F5;
  overflow-x: hidden;
  @extend .scroller;
}


app-nav {
  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
      background: transparent;
    }
  }

  .mat-expansion-panel {
    border-radius: 0 !important;

    &.nav-parent-responsive {
      @include extrasmall {
        display: block;
      }

      @include small {
        display: block;
      }

      @include desktop {
        display: none;
      }
    }

    &.nav-parent {
      @include desktop {
        display: block;
      }
    }

    &.nav-parent-active {
      .header-nav-parent {
        border-radius: 0 !important;
        color: $primary-color-light;

        .mat-expansion-panel-header-title {
          color: $primary-color-light;
          font-weight: bold;

          .menu-item-icon {
            color: $primary-color-light !important;
          }
        }
      }
    }

    .mat-expansion-panel-header {
      max-height: 48px !important;
      height: 48px !important;

      .mat-expansion-indicator {
        &::after {
          color: #FFFFFF !important;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
      margin: 0 0 0 35px !important;

      .mat-expansion-panel {
        background-color: #32393c !important;
        border-left: 2px solid #d6d6d6;

        &.nav-item-active {

          .mat-expansion-panel-header-title {
            color: $primary-color-light;
            font-weight: bold;
          }
        }

        .mat-expansion-panel-header {
          border-top: none !important;

          .mat-expansion-indicator {
            display: none;
          }
        }
      }
    }
  }

}

.center-horizontal {
  margin: 0 auto;
}

.align-right {
  text-align: right;
}

.title-1 {
  min-height: 43px;
  width: 305px;
  color: #495356;
  font-size: 32px;
  font-weight: bold;
  line-height: 43px;
}

.title-2 {
  color: #495356;
  font-size: 16px;
  line-height: 24px;
}

.title-3 {
  color: #495356;
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-bottom: 15px;
}

.title-4 {
  color: #495356;
  font-size: 12px;
  position: relative;
  line-height: 17px;
  margin: 0;
}

.separator-horizontal {
  display: block;
  margin-bottom: 26px;
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.label-field {
  color: #495356;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.carousel {

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

  .carousel-indicators {
    right: 30px !important;
    left: initial !important;
    margin: 0 !important;
    bottom: 50px;

    li {
      width: 10px !important;
      height: 10px !important;
      border-radius: 50% !important;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 100%;

      .carousel-caption {
        right: 20px;
        bottom: 126px;
        left: initial;
        width: 318px;
        text-align: right;
        line-height: 45px;
      }

      .picsum-img-wrapper {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

  }
}

.md-form {
  width: 100%;
  display: block;

  &:first-child {
    margin-bottom: 44px;
  }

  label {
    position: relative;
    width: 100%;
  }

  .form-control-file {
    margin-top: 13px;
  }
}

app-login,
app-forgot-password,
app-register {
  display: flex;
  width: 100%;
}

.button-disabled {
  cursor: no-drop !important;
}

.button-width-auto {
  width: auto !important;
}

.mat-radio-button {
  display: block !important;
}

.mat-form-field {
  width: 100%;
}

.wrapper-collection-point-detail {
  .mat-form-field-label {
    font-size: 20px !important;
  }
}

.icon-view {
  position: absolute;
  right: 0px;
  top: 41px;
  cursor: pointer;
}

button {

  &.mat-raised-button {
    height: 48px;

    .mat-button-wrapper {
      color: #FFFFFF;
      font-size: 18px;
    }
  }

  &.primary {
    background-color: #02D7DC !important;
    color: #fff;
  }

  &.primary-secondary {
    background-color: #FFF !important;
    border: 2px solid #02D7DC;
    margin: 0 5px;

    @media only screen and (max-width: 450px) {
      margin: 0;
    }

    .mat-button-wrapper {
      color: #02D7DC;
      background-color: #FFF !important;
    }

  }

  &.secondary-sharp {
    background-color: #FFF !important;
    border: 2px solid $secondary-color-dark;
    margin: 0 5px;

    .mat-button-wrapper {
      color: $secondary-color-dark;
      background-color: #FFF !important;
    }

  }

  &.primary-secondary1 {
    background-color: #373737 !important;
    border: 0.5px solid #373737;


    .mat-button-wrapper {
      color: #FFF;
    }

  }

  &.secondary-color-solid {
    background-color: $secondary-color-dark !important;
    color: #FFF !important;
  }

  &.warn {
    background-color: #FFF !important;
    border: 0.7px solid #584796;


    .mat-button-wrapper {
      color: #584796;
      background-color: #FFF !important;
    }

  }
}

mat-card-actions {
  button {
    width: 100%;
    background-color: $primary-color-light !important;
    font-size: 22px !important;
    font-weight: bold;
    height: 42px;
  }


}

.datepicker-fixed {
  width: 100%;
  border: none !important;
  background-color: #FFF;

  .ngb-dp-header {
    background-color: #FFFFFF !important;

    .ngb-dp-navigation-select {
      display: none !important;
    }

    .ngb-dp-arrow {
      .ngb-dp-arrow-btn {
        margin-top: 32px;
        color: #02D7DC;
        height: 28px;
      }
    }

    .ngb-dp-month-name {
      background-color: #FFFFFF !important;
      margin-bottom: 20px;
      background-color: #FFFFFF !important;
      margin-bottom: 20px;
      color: #495356;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 3.23px;
      line-height: 21.85px;
      text-transform: uppercase;
    }
  }

  .ngb-dp-months {
    width: 100%;
    background-color: #FFFFFF;

    .ngb-dp-month {
      width: 50%;


      .ngb-dp-weekdays {
        border-bottom: none;

        .ngb-dp-weekday {
          color: #495356;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
        }
      }

      .ngb-dp-month-name {
        background-color: #FFFFFF !important;
      }

      .ngb-dp-day {
        display: flex;
        justify-content: center;
        margin: 12px 0 10px 0;

        .btn-light.ng-star-inserted {
          border-radius: 50%;
          border: 1px solid #495356;
          background-color: #FFF !important;
          color: #495356 !important;
          font-size: 12px;
          line-height: 28px;

          &.bg-primary {
            background-color: #02D7DC !important;
            color: #FFF !important;
            border: 1px solid #02D7DC !important;
          }
        }
      }

      .ngb-dp-day,
      .ngb-dp-week-number,
      .ngb-dp-weekday {
        width: 100%;
      }
    }

    .ngb-dp-month:last-child .ngb-dp-week {
      display: flex;
      background-color: #FFFFFF !important;
    }

    .ngb-dp-month+.ngb-dp-month .ngb-dp-month-name,
    .ngb-dp-month+.ngb-dp-month .ngb-dp-week {
      background-color: #FFFFFF !important;
    }

    .bg-light {
      background-color: #FFFFFF !important;
    }

    @include only-tablet {
      display: flex;
      flex-direction: column;

      .ngb-dp-month {
        width: 100%;
      }
    }


  }

  &.datepicker-fixed--one-month {
    .ngb-dp-months {
      .ngb-dp-month {
        width: 100% !important;
      }
    }

    .ngb-dp-day {
      .btn-light {
        background-color: $primary-color-light !important;
        opacity: 1;
        font-weight: bold;
        border: 2px solid $primary-color-light !important;
      }

      &.disabled {
        .btn-light {
          opacity: .5 !important;
          font-weight: 100 !important;
          border: 1px solid #495356 !important;
        }
      }
    }
  }
}


.mat-tab-label-container {
  .mat-ink-bar {
    border-radius: 5px;
    height: 5px;
    background-color: #02d7dc !important;
  }

  .mat-tab-label-active {
    color: #02d7dc !important;
  }
}

.mat-tab-label {
  border-radius: 10px 10px 0 0 !important;
  height: 48px !important;
  background-color: #FFFFFF !important;
  opacity: 1 !important;
  margin-right: 6px;

  .mat-tab-label-content {
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 24px !important;
  }
}

.mat-tab-header {
  border: none !important;

  &-pagination {
    z-index: 1 !important;
  }
}

.row.ng-star-inserted {
  margin: 0px !important;
}

.mat-panel-header-destination {

  &.mat-expanded {
    .element-hide {
      display: none !important;
    }
  }

  .mat-content {
    display: block !important;
  }
}

.mat-card-content,
.mat-accordion {
  display: block;
}

.mat-card.message-card {
  background: #FAE5E4;
  padding: 10px !important;
  color: #E2574C;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  box-shadow: none !important;
  margin: 20px 0;
}

.mat-card-success {
  background-color: #e4fae6 !important;
  color: $primary-color-light !important;
}

.color-primary {
  color: $primary-color-light !important;
}

.bg-color-primary {
  background-color: $primary-color-light !important;
}

.color-alert {
  color: #f44336 !important;
}

.color-secondary-dark {
  color: $secondary-color-dark !important;
}

.bg-color-secondary-dark {
  background-color: $secondary-color-dark !important;
}

.color-primary-dark {
  color: $primary-color-dark !important;
}

.bg-color-primary-dark {
  background-color: $primary-color-dark !important;
}

.mat-button-toggle-group {
  height: 41px !important;
  border: 2px solid #02d7dc !important;
  font-weight: bold !important;
}

.mat-button-toggle-checked {
  background-color: #02d7dc;

  .mat-button-toggle-label-content {
    color: #FFFFFF !important;
  }
}

.mat-button-toggle-button {
  height: 100% !important;
}

.mat-button-toggle-label-content {
  height: 100% !important;
  line-height: 42px !important;
  color: #02d7dc;
}

.value-load-button-toggle {
  width: 100%;

  .mat-button-toggle {
    width: 50%;
  }
}

.mat-dialog-container {
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  padding: 0 !important;
  @extend .scroller;
}

.mat-dialog-content {
  @extend .scroller;
}


app-contact {
  .mat-list-item-content {
    padding: 0 !important;
  }
}

app-type-trip,
app-type-regime,
app-type-cargo,
app-container-measure,
app-type-service,
app-type-merchandise,
app-type-vehicle,
app-quantity-vehicles,
app-load-measurements,
app-upload-freight,
app-download-freight,
app-additional-service,
app-cargo-value,
app-method-payment,
app-total-value,
// app-upload-download-map-freight,
app-date-cargo,
app-tracking,
app-manual-creation-cargo,
// app-cargo-tabs-create,
app-freight-forwarder-detail,
app-change-password,
app-account-validation,
app-privacy-policy,
app-terms-conditions,
app-about-us,
app-admin-users-list,
app-reports {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

app-manual-creation-cargo {
  padding-top: 5px;
}

app-terms-conditions,
app-about-us,
app-change-password {
  background-color: #FFFFFF;
}

.underline {
  text-decoration: underline !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.label-back-step {
  color: #584796;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: underline;
  cursor: default !important;
  padding-left: 40px;

  figure {
    display: inline-block;
    width: 19px;
    margin: 0px 5px 0px 0px;

    img {
      width: 100%;
    }
  }
}

app-account-validation {
  padding: 50px 0;
}

app-about-us,
app-change-password,
app-terms-conditions {
  padding: 50px 10%;
}

app-change-password {
  height: 100%;
}

app-generate-guide {

  .mat-tab-body {
    padding-top: 30px;
  }

  .mat-tab-label {
    width: 50%;

    .mat-tab-label-content {
      font-size: 24px !important;
    }
  }
}

#mainFreightTabs {
  padding: 0 10%;
}

#mainFreightItinerary,
#mainFreightTabs {

  .row.ng-star-inserted {
    margin: 0px !important;
  }

  .mat-tab-body-wrapper {
    background-color: #FFFFFF;
    border-radius: 0 12px 0 0;
    margin-top: 7px;
    height: 100%;
    margin-left: -117px !important;
    margin-right: -148px !important;
  }

  .mat-tab-group,
  .mat-tab-body-wrapper {
    height: 100%;
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0px;
    margin-right: 0 !important;
    overflow: visible !important;

    .mat-tab-body {
      overflow: visible !important;

      .mat-tab-body-content {
        overflow: visible !important;

        app-upload-download-map-freight {
          position: relative;
          width: calc(100%);
        }
      }
    }
  }
}

.fullList {
  .mat-tab-body-wrapper {
    flex: 1;
  }
}

@include phone {
  .mat-paginator-container {
    .mat-paginator-range-label {
      margin: 0 !important;
    }

    padding: 0 !important;
  }
}

.container-itinerary-information {
  display: flex;
  margin: 0;
  padding: 0;

  .information-wrapper,
  .col-md-4 {
    display: inline-block;
  }

  .information-wrapper {
    margin-right: 24px;
    min-width: 184px;

    &:last-child {
      .md-form {
        border-right: none;
      }
    }

    .md-form {
      padding-right: 24px;
      border-right: 2px solid #ccc;
    }
  }

  .information-document-wrapper {
    width: 33.3333333333%;
  }

  .title-itinerary {
    color: #495356;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .value-itinerary {
    color: #495356;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
  }

  .date-itinerary {
    color: #495356;
    font-size: 17px;
    line-height: 26px;
    font-weight: bold;
  }

  .container-options-itinerary {
    margin: 0 -15px 0 0;
    padding: 0;
    text-align: right;
    max-width: 100%;
    flex: content;
    right: 28px;
    top: -3px;
    position: absolute;
  }

  .container-edit-itinerary {
    text-align: right;

    figure {
      display: inline-block;
      height: 16px;
      margin-right: 10px;

      img {
        height: 100%;
      }
    }

    .title-edit-itinerary {
      color: #03C9CD;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      text-decoration: underline;
      display: inline-block;
      cursor: pointer;
    }
  }
}

app-tracking {
  height: 100%;
}

app-upload-map-freight,
app-download-map-freight {
  height: 600px;
}

.tooltip-container-location {
  opacity: 1 !important;
  top: -4px !important;
  left: -3px !important;
  right: 0px !important;
  opacity: 1 !important;

  .arrow {
    display: none !important;
  }

  .tooltip-inner {
    background-color: #FFFFFF;
    padding: 0;
    max-width: 100%;
  }
}

.tooltip-inner {
  padding: 0 !important;
  border-radius: 5px !important;
  background-color: #FFF !important;
}


.locations-freight-accordion {
  >.mat-expansion-panel {
    &:last-child {
      .mat-expansion-panel-header {
        .mat-expansion-indicator {
          display: none !important;
        }
      }
    }
  }
}

.mat-panel-destinations {
  overflow: inherit !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.option-step-selected {
  border: 2px solid #00dfe5 !important;
}

.ng-scrollbar-wrapper[disabled=false][dir=ltr]>.scrollbar-control[track=vertical] {
  background-color: #FFF !important;
  width: 17px !important;
}

.ng-scrollbar {
  width: 100%;
  height: 100%;

  .ng-scroll-content {
    height: 100%;
    display: flex;
  }
}

.ng-scroll-offset.ng-scroll-viewport {
  width: 100%;
}

input {
  margin: 0;
}

.mat-progress-bar-fill::after {
  background-color: $primary-color-light !important;
}

.mat-vertical-stepper-content {
  height: auto !important;
  visibility: visible !important;
}

.text-strong {
  font-weight: bold;
  color: #757575;
}

.title-component {
  font-size: 28px;
  color: #373737;
  margin-bottom: 1.3rem;
  font-weight: bold;
}

.subtitle-component {
  font-size: 22px;
  color: #373737;
  margin-bottom: 2%;
  font-weight: bold;
}

.separator {
  height: 1px !important;
  width: 100%;
  margin: 20px 0px;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(28, 217, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

  &_center {
    height: 1px !important;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(28, 217, 255, 1) 51%, rgba(255, 255, 255, 1) 100%);
  }
}

.title-align-form-field {
  margin-bottom: 28px;
  margin-top: 20px;
}

.stepper-tracking {
  .mat-stepper-vertical-line::before {
    top: 0px !important;
    bottom: 0px !important;
    border-left-width: 2px !important;
  }

  .mat-step-header {
    padding: 3px !important;
    pointer-events: none;

    &:hover {
      background-color: transparent;
    }
  }


  .mat-vertical-content-container {
    margin-left: 15px !important;
  }
}

.mat-standard-chip {
  height: auto !important;
}


.wrapper-modal {
  .modal-dialog {
    .modal-content {
      width: calc(100% + 130px);
      border-radius: 10px;
      box-shadow: 2px 2px 15px 0 rgba(73, 83, 86, 0.2);

      .container-checks {

        mat-checkbox {
          width: 25%;
          margin-right: 38px;

          &:last-child,
          &:nth-child(3) {
            margin-right: 0px !important;

            .mat-checkbox-layout {
              justify-items: flex-end !important;
              width: 100% !important;
            }
          }

          .mat-checkbox-frame {
            border-color: #02d7dc !important;
          }

          .mat-checkbox-label {
            font-weight: bold;
          }

          .mat-checkbox-layout {
            width: 100%;
            text-align: center;
          }
        }
      }





    }
  }
}

.mat-form-date-picker {
  .mat-button-wrapper {
    color: initial !important;
  }

  .mat-form-field-infix {
    margin-top: -6px;

    .mat-form-field-label {
      span {
        margin-left: 36px;
      }
    }
  }

  .mat-form-field-suffix {
    left: 0px;
    position: absolute;
    top: 8px;
    width: 31px;
    font-size: 21px;
  }
}

.error-snackbar {
  background-color: #CF000F;
  color: #FFFFFF;
}

.success-snackbar {
  background-color: #009944;
  color: #FFFFFF;
}

.error-snackbar {
  background-color: #CF000F;
  color: #FFFFFF;
}

.alert-snackbar {
  background-color: #c4a000;
  color: #FFFFFF;
}

.info-snackbar {
  background-color: #02D7DC;
  color: #133845;
}

.mat-snack-bar-container {
  font-weight: bold;

  button {
    * {
      font-weight: bold;
    }
  }
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  font-size: 9px;
  top: 3px;

}

.contaner-icon-profile {
  width: 100%;
  text-align: right;

  img {
    background: #eaeaea;
    border-radius: 10px;
    padding: 12px;
    height: 73px;
  }
}

.container-list {

  margin: 20px;
  background-color: #FFFFFF;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
  border: 1px solid rgb(219 219 219 / 54%);
  display: flex;
  flex-direction: column;

  @include extrasmall {
    margin: 20px 0;
  }

  .container-list-header {
    padding: 0 12px;
    border-bottom: 1px solid #f5f5f5;
    flex: auto;

    .header-list-options {

      .mat-card-actions {
        display: block;

        button {
          width: auto;
          padding: 0 13px;
          border: none;

          .mat-button-wrapper {
            font-size: 15px;
            color: #495356;
            position: relative;
            z-index: 1;
          }

          .mat-button-ripple {
            background: #f5f5f5;
          }
        }
      }
    }

  }

  .container-list-filter {
    padding-top: 5px;
    flex: auto;

    .filter-actions {
      height: min-content;
    }

    .input-icon {
      overflow: hidden;

      .mat-form-field-infix {
        .icon-input {
          position: absolute;
          left: 0px;
          top: 5px;
          width: 23px;
          background-color: #FFF;
          margin: 0;
          height: 29px;
          padding: 2px;

          img {
            width: 100%;
          }
        }

        input {
          padding-left: 35px;
        }

        .mat-form-field-label-wrapper {
          margin-left: 25px;
        }
      }

    }

    .mat-card-actions {
      button {
        width: auto;
      }
    }

    .filter-item {
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      color: #929292;
      cursor: pointer;
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #CCCCCC;

      &:last-child {
        border-right: none;
      }

      &.filter-item-active {
        color: #584796 !important;
        text-decoration: underline;
      }
    }
  }

  .container-list-body {
    flex: auto;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    @extend .scroller;


  }
}

.list-select-all {
  display: flex;
  justify-content: flex-end;

  .check-all-title {
    padding: 0 15px;
    margin: 5px 0;
    text-align: right;
    font-weight: bold;
    cursor: pointer;
    color: #676767;

    @include only-tablet {
      display: contents;
    }
  }
}

.item-list-selected {
  background-color: rgb(196, 196, 196) !important;
}

.item__list {
  border-radius: 10px;
  box-shadow: 0px 2px 3px #a2a2a252;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  gap: 15px;

  &--col {
    width: 100%;

    label {
      display: block;
      font-weight: bold;
      color: $secondary-color-dark;
    }

    span {
      display: block;
      color: $primary-color-dark;
    }
  }
}

#containerUserInactive {
  position: fixed;
  top: 72px;
  z-index: 2;
  left: 240px;
  width: calc(100% - 257px);
  box-shadow: -1px 5px 8px #495356;
  height: 78px;
  background-color: #E2FCFD;
  text-align: center;
  font-weight: bold;
  color: #584796;
  font-size: 22px;
  line-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 25px;

  span {
    margin: auto;
  }
}

.nav-vertical {
  .hide-expansion-panel {
    .header-nav-parent {
      .mat-expansion-indicator {
        display: none;
      }
    }
  }
}

.mat-form-date-picker {
  input {
    padding-left: 31px;
  }
}

#mainCreateCargoFromGuides {
  .mat-tab-header {
    margin-bottom: 20px;
  }
}

.min-h-100-margin {
  min-height: calc(100% - 40px) !important;
}

.h-100-margin {
  height: calc(100% - 20px) !important;
}

.w-100 {
  width: 100%;
}

#mainUploadGuides {

  .mat-tab-body-wrapper {
    background-color: #FFFFFF;
    border-radius: 0px 10px 10px 10px;
    padding: 18px;
  }
}

textarea,
input {
  outline: none !important;
}

.otp-input {
  border-radius: 10px !important;
  background-color: #FFFFFF !important;
  box-shadow: 2px 2px 15px 0 rgba(73, 83, 86, 0.2) !important;
  height: 60px !important;
  font-size: 25px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 58px !important;
  font-weight: bold;
  text-align: center;
  border-bottom: 0px solid transparent !important;
}

ng-otp-input {
  .wrapper {
    text-align: center;
  }
}

.width-100 {
  width: 100%;
}

.margin-auto {
  margin: auto;
}

i.fas,
i.far {
  padding: 4px;
}

.font-primary-dark {
  color: $primary-color-dark !important;
}

.font-primary-light {
  color: $primary-color-light !important;
}

.font-secondary-dark {
  color: $secondary-color-dark !important;
}

.pac-container {
  z-index: 1052 !important;
}


.field__form {
  position: relative;

  &--icon {
    position: absolute;
    right: 0;
    bottom: 3px;
  }

  &--input {
    padding-right: 25px !important;
  }
}

.spinner-input {
  position: absolute;
  right: 0;
}

.pattern {
  font-size: 12px;
  bottom: -16px;
  position: absolute;
  font-weight: bold;
  width: 100%;
  display: block;
  text-align: center;
  letter-spacing: 0.4px;

  &--top {
    top: -3px !important;
    font-size: 16px;
  }

  &--info {
    color: $primary-color-light;
  }

  &--invalid {
    color: #f44336;
  }

  &--warning {
    color: #ffc107 !important;
  }

  &--autocomplete {
    bottom: -1px !important;
  }
}

.text__color {
  &.color-- {
    &error {
      color: #f44336 !important;
    }

    &success {
      color: #009944 !important;
    }

    &warning {
      color: #ffc107 !important;
    }
  }
}


.color-- {
  &error {
    color: #f44336 !important;
  }

  &success {
    color: #009944 !important;
  }

  &warning {
    color: #ffc107 !important;
  }
}

.text-underlined {
  cursor: pointer;
  text-decoration: underline !important;
}

.asterisk {
  color: #f44336 !important;
  font-weight: 500;
}

.optional {
  color: #C2BECA;
  font-weight: 100;
  margin-left: 5px;
}

.field__card {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &--label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  &--value {
    color: #495356;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }
}

.z__index {
  &--1 {
    z-index: 1;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;

  &--right-top {
    top: 0;
    right: 2%;
  }

  &--right-bottom {
    top: 1.5rem;
    right: 0;
  }

  &--right-bottom {
    top: 0rem;
    right: 1.5rem;
  }
}

.spinner-input {
  animation: rotation 2s infinite linear;
}


.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.company-select {
  padding-top: 9px;
}

.filter--company {
  margin-top: -13px;
}

app-cost-center-list {
  display: block;
  height: 100%;
}


.wrapper__article {
  height: calc(100% - 40px);
  margin: 20px;
  background-color: #FFFFFF;
  padding: 4px;
  border-radius: 10px;
  border: 1px solid rgb(219 219 219 / 54%);
  overflow: hidden;

  @media only screen and (max-width: 400px) {
    margin: 20px 0;
  }

  &__title {
    padding: 20px;

    h5 {
      font-weight: bold;
      color: $primary-color-dark;
    }
  }

  &__body {
    overflow-y: auto;
    height: calc(100% - 76px);
    position: relative;

    @extend .scroller;
  }

  &__body--tab {
    height: calc(100% - 76px);
    position: relative;
    overflow: hidden;

    .mat-tab-group {
      height: 100%;
      overflow: hidden;

      .mat-tab-body-content {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &__content {
    height: 100%;
    position: relative;
    overflow: hidden;

    .mat-tab-group {
      height: 100%;
      overflow: hidden;

      .mat-tab-body-content {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &__form {
    height: 100%;

    &--content {
      height: calc(100% - 66px);
      padding: 10px;
      @extend .scroller;
    }

    &--footer {
      .mat-card-actions {
        display: flex;
        justify-content: center;
        gap: 15px;
      }
    }
  }
}

.icon__bill {
  height: 25px;
  position: absolute;
  left: -33px;
  top: -5px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  margin: 3px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    margin-bottom: 2px;
  }
}

carousel {
  .carousel-cell {
    img {
      object-fit: scale-down !important;
    }
  }
}

.cancel-value-select {
  position: relative;
  top: -10px;
  right: 6px;
  font-weight: bold;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.container-flex {
  display: flex !important;

  &_row {
    display: flex;
    flex-direction: row !important;
  }

  &_col {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  &_center {
    justify-content: center;
    align-items: center;
  }

}

.txt {
  &_center {
    text-align: center;
  }

  &_left {
    text-align: left;
  }

  &_right {
    text-align: right;
  }
}

.aling-conten {
  &_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_start-up {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_start-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.border-intermitente-card {
  animation: border 10s ease-out infinite;
}

@keyframes border {

  0%,
  100% {}

  50% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
}

.bell {
  -webkit-animation: tiembla 0.3s infinite;
}

@-webkit-keyframes tiembla {
  0% {
    -webkit-transform: rotateZ(-5deg);
  }

  50% {
    -webkit-transform: rotateZ(0deg) scale(.8);
  }

  100% {
    -webkit-transform: rotateZ(5deg);
  }
}

.message {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &--info {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
}

.display-none {
  display: none !important;
}


.close-icon-actions {
  cursor: pointer;
  color: #6a7376;
  position: absolute;
  right: 0.5em;
  top: 0.8em;
  z-index: 2;
  font-size: 28px;

  &:hover {
    color: #02d7dc;
  }
}

.mat-form-field-appearance-outline {
  & .mat-form-field-flex {
    background-color: #FFFFFF !important;
    // padding-right: 0.45rem !important;
    padding: 0 !important;
    padding-left: 12px !important;
  }

  & .mat-form-field-outline {
    top: 0 !important;
  }
}

.clean-filter-icon {

  position: absolute;
  top: 42%;
  right: 2px;
  /* Ajusta la posición según lo necesario */
  transform: translateY(-50%);
  // pointer-events: none;
  cursor: pointer;
  border-radius: 6px;
  color: #666666;
  margin-right: 8px;
  font-size: 18px;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-input-element {
  padding-right: 34px !important;
}


.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  & button {
    margin-top: 0.5rem !important;
  }
}

.conv-btn {
  padding: 2px 5px;
  border: 2px solid white;
  font-weight: bolder;
  border-radius: 10px;
  color: black;
  cursor: pointer;

  &:hover {
    color: $primary-color-light;
  }

  i {
    animation: rainbow 4s infinite linear;
  }
}

@keyframes rainbow {
  0% {
    color: yellow;
  }

  50% {
    color: red;
  }

  100% {
    color: yellow;
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

mat-error {
  font-weight: bold;
}

.custom-mat-error {
  transform: translateY(-15px);
  font-size: 12px;
  font-weight: bold;
}

.mat-form-field-appearance-outline {
  & .mat-form-field-flex {
    background-color: #FFFFFF !important;
    padding-right: 8px !important;
  }

  & .mat-form-field-outline {
    top: 0 !important;
  }

  & .mat-form-field-invalid {
    color: #f44336 !important;
  }
}

.mat-simple-snackbar-action {
  border-radius: 10px;
  color: #495356 !important;
}

.mat-simple-snackbar-action:hover {
  color: white !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8ee7e9 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8ee7e9;
  border-radius: 0px;
  border: 3px solid #8ee7e9;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  align-items: center;
}

app-preview-notification {
  display: inline-block;
}

.custom-tooltip {
  white-space: pre-line;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.disabled-btn {
  background-color: #8f8f90 !important;
  color: #c6c6c6 !important;

  span {
    color: #c6c6c6 !important;
  }
}

.secondary-button-t-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: bold;

  .button-t {
    max-width: 100%;
    background-color: white;
    color: $primary-color-light;
    border-radius: 10px;
    text-align: center;
    padding: 2px 40px;
    height: auto;
    cursor: pointer;
    border: 2px solid $primary-color-light;

    @media only screen and (min-width: 1200px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 420px) {
      width: 100%;
    }

    &.selected {
      color: white;
      background-color: $primary-color-light;
    }
  }
}

/* Estilos para toggle personalizado */
.custom-slide-toggle {
  position: relative;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  & input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider {
      background-color: #00e2d1;
    }

    &:checked+.slider:before {
      transform: translateX(26px);
    }
  }

  &__toggle-label {
    font-size: 16px;
    color: $primary-color-dark;
  }
}

.slider {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 12px;
  transition: background-color 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}


.read-only-stepper .mat-step-header {
  pointer-events: none; /* Desactiva clics en los tabs superiores */
  cursor: default;
}