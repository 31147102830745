@charset "UTF-8";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans Regular";
  src: url("/assets/fonts/Open_Sans/open_sans_regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Italic";
  src: url("/assets/fonts/Open_Sans/open_sans_italic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold";
  src: url("/assets/fonts/Open_Sans/open_sans_bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold Itailc";
  src: url("/assets/fonts/Open_Sans/open_sans_bold_italic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito Bold Italic";
  src: url("/assets/fonts/Nunito/nunito_bold_italic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito Bold";
  src: url("/assets/fonts/Nunito/nunito_bold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito Italic";
  src: url("/assets/fonts/Nunito/nunito_italic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito Regular";
  src: url("/assets/fonts/Nunito/nunito_regular.ttf") format("truetype");
}
.scroller, .wrapper__article__form--content, .wrapper__article__body, .container-list .container-list-body, .mat-dialog-content, .mat-dialog-container, body {
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-color: rgba(2, 215, 220, 0.42) transparent;
  scrollbar-width: thin !important;
}
.scroller::-webkit-scrollbar-track, .wrapper__article__form--content::-webkit-scrollbar-track, .wrapper__article__body::-webkit-scrollbar-track, .container-list .container-list-body::-webkit-scrollbar-track, .mat-dialog-content::-webkit-scrollbar-track, .mat-dialog-container::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}
.scroller::-webkit-scrollbar, .wrapper__article__form--content::-webkit-scrollbar, .wrapper__article__body::-webkit-scrollbar, .container-list .container-list-body::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, .mat-dialog-container::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  background-color: transparent;
}
.scroller::-webkit-scrollbar-thumb, .wrapper__article__form--content::-webkit-scrollbar-thumb, .wrapper__article__body::-webkit-scrollbar-thumb, .container-list .container-list-body::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, .mat-dialog-container::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: rgba(2, 215, 220, 0.42);
}

.mat-step-header .mat-step-icon {
  background: #584796 !important;
  color: white !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background: #02d7dc !important;
  color: white !important;
}

.container-input-date-picker {
  font-size: 15px;
  margin-top: 5px;
}
.container-input-date-picker input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.mat-calendar-body .mat-calendar-body-selected {
  background-color: #02d7dc;
  font-weight: bold;
  color: #FFF;
}

.cdk-overlay-container {
  z-index: 1051 !important;
}

.mat-datetimepicker-calendar-header {
  background-color: #02d7dc;
  color: #FFF;
}

.mat-input-element[type=time] {
  position: relative;
}
.mat-input-element[type=time]::after {
  content: none !important;
}
.mat-input-element[type=time]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  right: 10px;
  color: 0, 0, 0, 0.54 !important;
  cursor: pointer !important;
}
.mat-input-element[type=time] .mat-input-element {
  padding-right: 0 !important;
}

.mat-input-element {
  box-shadow: none !important;
  outline: none !important;
}

.mat-expansion-panel {
  margin: 0 !important;
}

.mat-card-actions {
  margin-top: 20px;
  width: 100%;
}

.mat-step-label {
  width: 100%;
}

.stepper-tracking .mat-vertical-content {
  padding: 0 2px 24px 24px;
}

app-download-location .mat-content {
  position: relative !important;
}

.tooltip__alert {
  font-size: 14px;
  max-width: 320px !important;
  background-color: #584796 !important;
  text-align: center;
}

.tooltip__error {
  font-size: 14px;
  max-width: 320px !important;
  background-color: #e72121 !important;
  text-align: center;
}

.mat-card {
  padding: 15px !important;
}

.mat-form-field-check {
  padding-top: 40px;
}

.mat-expansion-panel-header-description-right {
  text-align: revert;
  font-size: 12px;
  font-weight: bold;
  flex-direction: row-reverse;
}

.mat-expansion-panel-header-description-left {
  text-align: start;
  font-size: 12px;
  font-weight: bold;
}

app-confirm-ministry .mat-button-toggle-label-content {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 24px;
}
app-confirm-ministry .mat-button-toggle {
  width: 50%;
}
app-confirm-ministry .mat-button-toggle-group {
  width: 100%;
  height: 60px !important;
}

.cargo__options--menu .mat-menu-item {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e9e9e9;
}
.cargo__options--menu .mat-menu-item:hover {
  background-color: #d9d8d8 !important;
  font-weight: bold;
  color: #495356;
}

.stepper-form-vehicle .mat-horizontal-stepper-header {
  padding: 0 24px !important;
}
.stepper-form-vehicle .mat-horizontal-stepper-header .mat-step-label {
  padding-top: 4px !important;
}
.stepper-form-vehicle .mat-horizontal-stepper-header .mat-step-optional {
  display: none;
}
.stepper-form-vehicle .mat-horizontal-stepper-header::before, .stepper-form-vehicle .mat-horizontal-stepper-header::after {
  border: none !important;
}

app-contact-person-vehicle .mat-form-field-flex,
app-gps-vehicle .mat-form-field-flex,
.form__vehicle_documents .mat-form-field-flex {
  padding: 0 !important;
}

.routing__form .mat-horizontal-stepper-header-container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}
.routing__form .mat-horizontal-content-container {
  background: #fff;
  border-radius: 10px;
  height: calc(100% - 130px);
  padding: 30px;
}
@media only screen and (max-width: 576px) {
  .routing__form .mat-horizontal-content-container {
    padding-inline: 10px;
  }
}
.fleet__filter .mat-form-field-wrapper {
  margin: 0;
  border: 0;
  padding: 0;
}
.fleet__filter .mat-form-field-infix {
  padding: 10px 0 !important;
  border: 0 !important;
}

.fleet__actions_body.mat-card-actions {
  padding: 0px 16px 16px 16px;
  display: block;
  margin: 0;
}

.vehicle-transport .mat-button-wrapper {
  font-size: 1em !important;
}

.cargo__header--actions {
  justify-content: end;
  gap: 10px;
}
.cargo__header--actions button {
  height: 30px !important;
  line-height: 0 !important;
}
.cargo__header--actions button .mat-button-wrapper {
  font-size: 14px !important;
}

.scroller, .wrapper__article__form--content, .wrapper__article__body, .container-list .container-list-body, .mat-dialog-content, .mat-dialog-container, body {
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-color: rgba(2, 215, 220, 0.42) transparent;
  scrollbar-width: thin !important;
}
.scroller::-webkit-scrollbar-track, .wrapper__article__form--content::-webkit-scrollbar-track, .wrapper__article__body::-webkit-scrollbar-track, .container-list .container-list-body::-webkit-scrollbar-track, .mat-dialog-content::-webkit-scrollbar-track, .mat-dialog-container::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}
.scroller::-webkit-scrollbar, .wrapper__article__form--content::-webkit-scrollbar, .wrapper__article__body::-webkit-scrollbar, .container-list .container-list-body::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, .mat-dialog-container::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  background-color: transparent;
}
.scroller::-webkit-scrollbar-thumb, .wrapper__article__form--content::-webkit-scrollbar-thumb, .wrapper__article__body::-webkit-scrollbar-thumb, .container-list .container-list-body::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, .mat-dialog-container::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: rgba(2, 215, 220, 0.42);
}

/* Add application styles & imports to this file! */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #495356;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #02d7dc;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #495356;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #02d7dc;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: #584796;
  background: #495356;
}
@media (-ms-high-contrast: active) {
  .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

.mat-badge-accent .mat-badge-content {
  background: #02d7dc;
  color: #584796;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #495356;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #02d7dc;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #495356;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #02d7dc;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: #584796;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #584796;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #495356;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #02d7dc;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(88, 71, 150, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(88, 71, 150, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
@media (-ms-high-contrast: black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important;
  }
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #495356;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #02d7dc;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
@media (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5;
  }
}

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none;
  }
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #495356;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #02d7dc;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #495356;
  color: #584796;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #584796;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background: rgba(88, 71, 150, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #02d7dc;
  color: #584796;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #584796;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background: rgba(88, 71, 150, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #495356;
  color: #584796;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(73, 83, 86, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #584796;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #02d7dc;
  color: #584796;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(2, 215, 220, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #584796;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #495356;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #02d7dc;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #495356;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #02d7dc;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #02d7dc;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #495356;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #02d7dc;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #495356;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #02d7dc;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #495356;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #02d7dc;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #495356;
}
.mat-icon.mat-accent {
  color: #02d7dc;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #495356;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #02d7dc;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #c8cbcc;
}

.mat-progress-bar-buffer {
  background-color: #c8cbcc;
}

.mat-progress-bar-fill::after {
  background-color: #495356;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3f3f5;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3f3f5;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #02d7dc;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #495356;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #02d7dc;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #495356;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #495356;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #02d7dc;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #02d7dc;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #495356;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #02d7dc;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #02d7dc;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(2, 215, 220, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #02d7dc;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #495356;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(73, 83, 86, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #495356;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #495356;
}
.mat-primary .mat-slider-thumb-label-text {
  color: #584796;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #02d7dc;
}
.mat-accent .mat-slider-thumb-label-text {
  color: #584796;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(2, 215, 220, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: #584796;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #495356;
  color: #584796;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 203, 204, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #495356;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: #584796;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 243, 245, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #02d7dc;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: #584796;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 203, 204, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #495356;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: #584796;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(88, 71, 150, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: #584796;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(88, 71, 150, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(88, 71, 150, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 243, 245, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #02d7dc;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: #584796;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(88, 71, 150, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: #584796;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(88, 71, 150, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(88, 71, 150, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #495356;
  color: #584796;
}
.mat-toolbar.mat-accent {
  background: #02d7dc;
  color: #584796;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #02d7dc;
}

mat-card {
  padding: 0px !important;
}
mat-card mat-card-title,
mat-card mat-card-content {
  padding: 0.5em;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

[hidden] {
  display: none !important;
}

.flex-left {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #F5F5F5;
  overflow-x: hidden;
}

@media (hover: none) {
  app-nav .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: transparent;
  }
}
app-nav .mat-expansion-panel {
  border-radius: 0 !important;
}
@media only screen and (max-width: 576px) {
  app-nav .mat-expansion-panel.nav-parent-responsive {
    display: block;
  }
}
@media only screen and (min-width: 576px) {
  app-nav .mat-expansion-panel.nav-parent-responsive {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  app-nav .mat-expansion-panel.nav-parent-responsive {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  app-nav .mat-expansion-panel.nav-parent {
    display: block;
  }
}
app-nav .mat-expansion-panel.nav-parent-active .header-nav-parent {
  border-radius: 0 !important;
  color: #02d7dc;
}
app-nav .mat-expansion-panel.nav-parent-active .header-nav-parent .mat-expansion-panel-header-title {
  color: #02d7dc;
  font-weight: bold;
}
app-nav .mat-expansion-panel.nav-parent-active .header-nav-parent .mat-expansion-panel-header-title .menu-item-icon {
  color: #02d7dc !important;
}
app-nav .mat-expansion-panel .mat-expansion-panel-header {
  max-height: 48px !important;
  height: 48px !important;
}
app-nav .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: #FFFFFF !important;
}
app-nav .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
  margin: 0 0 0 35px !important;
}
app-nav .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel {
  background-color: #32393c !important;
  border-left: 2px solid #d6d6d6;
}
app-nav .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel.nav-item-active .mat-expansion-panel-header-title {
  color: #02d7dc;
  font-weight: bold;
}
app-nav .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel .mat-expansion-panel-header {
  border-top: none !important;
}
app-nav .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}

.center-horizontal {
  margin: 0 auto;
}

.align-right {
  text-align: right;
}

.title-1 {
  min-height: 43px;
  width: 305px;
  color: #495356;
  font-size: 32px;
  font-weight: bold;
  line-height: 43px;
}

.title-2 {
  color: #495356;
  font-size: 16px;
  line-height: 24px;
}

.title-3 {
  color: #495356;
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-bottom: 15px;
}

.title-4 {
  color: #495356;
  font-size: 12px;
  position: relative;
  line-height: 17px;
  margin: 0;
}

.separator-horizontal {
  display: block;
  margin-bottom: 26px;
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.label-field {
  color: #495356;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  display: none;
}
.carousel .carousel-indicators {
  right: 30px !important;
  left: initial !important;
  margin: 0 !important;
  bottom: 50px;
}
.carousel .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  margin-right: 20px;
}
.carousel .carousel-indicators li:last-child {
  margin-right: 0px;
}
.carousel .carousel-inner {
  height: 100%;
}
.carousel .carousel-inner .carousel-item {
  height: 100%;
}
.carousel .carousel-inner .carousel-item .carousel-caption {
  right: 20px;
  bottom: 126px;
  left: initial;
  width: 318px;
  text-align: right;
  line-height: 45px;
}
.carousel .carousel-inner .carousel-item .picsum-img-wrapper {
  height: 100%;
}
.carousel .carousel-inner .carousel-item .picsum-img-wrapper img {
  width: 100%;
  height: 100%;
}

.md-form {
  width: 100%;
  display: block;
}
.md-form:first-child {
  margin-bottom: 44px;
}
.md-form label {
  position: relative;
  width: 100%;
}
.md-form .form-control-file {
  margin-top: 13px;
}

app-login,
app-forgot-password,
app-register {
  display: flex;
  width: 100%;
}

.button-disabled {
  cursor: no-drop !important;
}

.button-width-auto {
  width: auto !important;
}

.mat-radio-button {
  display: block !important;
}

.mat-form-field {
  width: 100%;
}

.wrapper-collection-point-detail .mat-form-field-label {
  font-size: 20px !important;
}

.icon-view {
  position: absolute;
  right: 0px;
  top: 41px;
  cursor: pointer;
}

button.mat-raised-button {
  height: 48px;
}
button.mat-raised-button .mat-button-wrapper {
  color: #FFFFFF;
  font-size: 18px;
}
button.primary {
  background-color: #02D7DC !important;
  color: #fff;
}
button.primary-secondary {
  background-color: #FFF !important;
  border: 2px solid #02D7DC;
  margin: 0 5px;
}
@media only screen and (max-width: 450px) {
  button.primary-secondary {
    margin: 0;
  }
}
button.primary-secondary .mat-button-wrapper {
  color: #02D7DC;
  background-color: #FFF !important;
}
button.secondary-sharp {
  background-color: #FFF !important;
  border: 2px solid #584796;
  margin: 0 5px;
}
button.secondary-sharp .mat-button-wrapper {
  color: #584796;
  background-color: #FFF !important;
}
button.primary-secondary1 {
  background-color: #373737 !important;
  border: 0.5px solid #373737;
}
button.primary-secondary1 .mat-button-wrapper {
  color: #FFF;
}
button.secondary-color-solid {
  background-color: #584796 !important;
  color: #FFF !important;
}
button.warn {
  background-color: #FFF !important;
  border: 0.7px solid #584796;
}
button.warn .mat-button-wrapper {
  color: #584796;
  background-color: #FFF !important;
}

mat-card-actions button {
  width: 100%;
  background-color: #02d7dc !important;
  font-size: 22px !important;
  font-weight: bold;
  height: 42px;
}

.datepicker-fixed {
  width: 100%;
  border: none !important;
  background-color: #FFF;
}
.datepicker-fixed .ngb-dp-header {
  background-color: #FFFFFF !important;
}
.datepicker-fixed .ngb-dp-header .ngb-dp-navigation-select {
  display: none !important;
}
.datepicker-fixed .ngb-dp-header .ngb-dp-arrow .ngb-dp-arrow-btn {
  margin-top: 32px;
  color: #02D7DC;
  height: 28px;
}
.datepicker-fixed .ngb-dp-header .ngb-dp-month-name {
  background-color: #FFFFFF !important;
  margin-bottom: 20px;
  background-color: #FFFFFF !important;
  margin-bottom: 20px;
  color: #495356;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3.23px;
  line-height: 21.85px;
  text-transform: uppercase;
}
.datepicker-fixed .ngb-dp-months {
  width: 100%;
  background-color: #FFFFFF;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month {
  width: 50%;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-weekdays {
  border-bottom: none;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-weekdays .ngb-dp-weekday {
  color: #495356;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-month-name {
  background-color: #FFFFFF !important;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-day {
  display: flex;
  justify-content: center;
  margin: 12px 0 10px 0;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-day .btn-light.ng-star-inserted {
  border-radius: 50%;
  border: 1px solid #495356;
  background-color: #FFF !important;
  color: #495356 !important;
  font-size: 12px;
  line-height: 28px;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-day .btn-light.ng-star-inserted.bg-primary {
  background-color: #02D7DC !important;
  color: #FFF !important;
  border: 1px solid #02D7DC !important;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-day,
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-week-number,
.datepicker-fixed .ngb-dp-months .ngb-dp-month .ngb-dp-weekday {
  width: 100%;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month:last-child .ngb-dp-week {
  display: flex;
  background-color: #FFFFFF !important;
}
.datepicker-fixed .ngb-dp-months .ngb-dp-month + .ngb-dp-month .ngb-dp-month-name,
.datepicker-fixed .ngb-dp-months .ngb-dp-month + .ngb-dp-month .ngb-dp-week {
  background-color: #FFFFFF !important;
}
.datepicker-fixed .ngb-dp-months .bg-light {
  background-color: #FFFFFF !important;
}
@media only screen and (max-width: 768px) {
  .datepicker-fixed .ngb-dp-months {
    display: flex;
    flex-direction: column;
  }
  .datepicker-fixed .ngb-dp-months .ngb-dp-month {
    width: 100%;
  }
}
.datepicker-fixed.datepicker-fixed--one-month .ngb-dp-months .ngb-dp-month {
  width: 100% !important;
}
.datepicker-fixed.datepicker-fixed--one-month .ngb-dp-day .btn-light {
  background-color: #02d7dc !important;
  opacity: 1;
  font-weight: bold;
  border: 2px solid #02d7dc !important;
}
.datepicker-fixed.datepicker-fixed--one-month .ngb-dp-day.disabled .btn-light {
  opacity: 0.5 !important;
  font-weight: 100 !important;
  border: 1px solid #495356 !important;
}

.mat-tab-label-container .mat-ink-bar {
  border-radius: 5px;
  height: 5px;
  background-color: #02d7dc !important;
}
.mat-tab-label-container .mat-tab-label-active {
  color: #02d7dc !important;
}

.mat-tab-label {
  border-radius: 10px 10px 0 0 !important;
  height: 48px !important;
  background-color: #FFFFFF !important;
  opacity: 1 !important;
  margin-right: 6px;
}
.mat-tab-label .mat-tab-label-content {
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 24px !important;
}

.mat-tab-header {
  border: none !important;
}
.mat-tab-header-pagination {
  z-index: 1 !important;
}

.row.ng-star-inserted {
  margin: 0px !important;
}

.mat-panel-header-destination.mat-expanded .element-hide {
  display: none !important;
}
.mat-panel-header-destination .mat-content {
  display: block !important;
}

.mat-card-content,
.mat-accordion {
  display: block;
}

.mat-card.message-card {
  background: #FAE5E4;
  padding: 10px !important;
  color: #E2574C;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  box-shadow: none !important;
  margin: 20px 0;
}

.mat-card-success {
  background-color: #e4fae6 !important;
  color: #02d7dc !important;
}

.color-primary {
  color: #02d7dc !important;
}

.bg-color-primary {
  background-color: #02d7dc !important;
}

.color-alert {
  color: #f44336 !important;
}

.color-secondary-dark {
  color: #584796 !important;
}

.bg-color-secondary-dark {
  background-color: #584796 !important;
}

.color-primary-dark {
  color: #495356 !important;
}

.bg-color-primary-dark {
  background-color: #495356 !important;
}

.mat-button-toggle-group {
  height: 41px !important;
  border: 2px solid #02d7dc !important;
  font-weight: bold !important;
}

.mat-button-toggle-checked {
  background-color: #02d7dc;
}
.mat-button-toggle-checked .mat-button-toggle-label-content {
  color: #FFFFFF !important;
}

.mat-button-toggle-button {
  height: 100% !important;
}

.mat-button-toggle-label-content {
  height: 100% !important;
  line-height: 42px !important;
  color: #02d7dc;
}

.value-load-button-toggle {
  width: 100%;
}
.value-load-button-toggle .mat-button-toggle {
  width: 50%;
}

.mat-dialog-container {
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  padding: 0 !important;
}

app-contact .mat-list-item-content {
  padding: 0 !important;
}

app-type-trip,
app-type-regime,
app-type-cargo,
app-container-measure,
app-type-service,
app-type-merchandise,
app-type-vehicle,
app-quantity-vehicles,
app-load-measurements,
app-upload-freight,
app-download-freight,
app-additional-service,
app-cargo-value,
app-method-payment,
app-total-value,
app-date-cargo,
app-tracking,
app-manual-creation-cargo,
app-freight-forwarder-detail,
app-change-password,
app-account-validation,
app-privacy-policy,
app-terms-conditions,
app-about-us,
app-admin-users-list,
app-reports {
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

app-manual-creation-cargo {
  padding-top: 5px;
}

app-terms-conditions,
app-about-us,
app-change-password {
  background-color: #FFFFFF;
}

.underline {
  text-decoration: underline !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.label-back-step {
  color: #584796;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: underline;
  cursor: default !important;
  padding-left: 40px;
}
.label-back-step figure {
  display: inline-block;
  width: 19px;
  margin: 0px 5px 0px 0px;
}
.label-back-step figure img {
  width: 100%;
}

app-account-validation {
  padding: 50px 0;
}

app-about-us,
app-change-password,
app-terms-conditions {
  padding: 50px 10%;
}

app-change-password {
  height: 100%;
}

app-generate-guide .mat-tab-body {
  padding-top: 30px;
}
app-generate-guide .mat-tab-label {
  width: 50%;
}
app-generate-guide .mat-tab-label .mat-tab-label-content {
  font-size: 24px !important;
}

#mainFreightTabs {
  padding: 0 10%;
}

#mainFreightItinerary .row.ng-star-inserted,
#mainFreightTabs .row.ng-star-inserted {
  margin: 0px !important;
}
#mainFreightItinerary .mat-tab-body-wrapper,
#mainFreightTabs .mat-tab-body-wrapper {
  background-color: #FFFFFF;
  border-radius: 0 12px 0 0;
  margin-top: 7px;
  height: 100%;
  margin-left: -117px !important;
  margin-right: -148px !important;
}
#mainFreightItinerary .mat-tab-group,
#mainFreightItinerary .mat-tab-body-wrapper,
#mainFreightTabs .mat-tab-group,
#mainFreightTabs .mat-tab-body-wrapper {
  height: 100%;
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 0px;
  margin-right: 0 !important;
  overflow: visible !important;
}
#mainFreightItinerary .mat-tab-group .mat-tab-body,
#mainFreightItinerary .mat-tab-body-wrapper .mat-tab-body,
#mainFreightTabs .mat-tab-group .mat-tab-body,
#mainFreightTabs .mat-tab-body-wrapper .mat-tab-body {
  overflow: visible !important;
}
#mainFreightItinerary .mat-tab-group .mat-tab-body .mat-tab-body-content,
#mainFreightItinerary .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content,
#mainFreightTabs .mat-tab-group .mat-tab-body .mat-tab-body-content,
#mainFreightTabs .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  overflow: visible !important;
}
#mainFreightItinerary .mat-tab-group .mat-tab-body .mat-tab-body-content app-upload-download-map-freight,
#mainFreightItinerary .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content app-upload-download-map-freight,
#mainFreightTabs .mat-tab-group .mat-tab-body .mat-tab-body-content app-upload-download-map-freight,
#mainFreightTabs .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content app-upload-download-map-freight {
  position: relative;
  width: calc(100%);
}

.fullList .mat-tab-body-wrapper {
  flex: 1;
}

@media only screen and (max-width: 450px) {
  .mat-paginator-container {
    padding: 0 !important;
  }
  .mat-paginator-container .mat-paginator-range-label {
    margin: 0 !important;
  }
}
.container-itinerary-information {
  display: flex;
  margin: 0;
  padding: 0;
}
.container-itinerary-information .information-wrapper,
.container-itinerary-information .col-md-4 {
  display: inline-block;
}
.container-itinerary-information .information-wrapper {
  margin-right: 24px;
  min-width: 184px;
}
.container-itinerary-information .information-wrapper:last-child .md-form {
  border-right: none;
}
.container-itinerary-information .information-wrapper .md-form {
  padding-right: 24px;
  border-right: 2px solid #ccc;
}
.container-itinerary-information .information-document-wrapper {
  width: 33.3333333333%;
}
.container-itinerary-information .title-itinerary {
  color: #495356;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
  font-weight: 400;
}
.container-itinerary-information .value-itinerary {
  color: #495356;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
}
.container-itinerary-information .date-itinerary {
  color: #495356;
  font-size: 17px;
  line-height: 26px;
  font-weight: bold;
}
.container-itinerary-information .container-options-itinerary {
  margin: 0 -15px 0 0;
  padding: 0;
  text-align: right;
  max-width: 100%;
  flex: content;
  right: 28px;
  top: -3px;
  position: absolute;
}
.container-itinerary-information .container-edit-itinerary {
  text-align: right;
}
.container-itinerary-information .container-edit-itinerary figure {
  display: inline-block;
  height: 16px;
  margin-right: 10px;
}
.container-itinerary-information .container-edit-itinerary figure img {
  height: 100%;
}
.container-itinerary-information .container-edit-itinerary .title-edit-itinerary {
  color: #03C9CD;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}

app-tracking {
  height: 100%;
}

app-upload-map-freight,
app-download-map-freight {
  height: 600px;
}

.tooltip-container-location {
  opacity: 1 !important;
  top: -4px !important;
  left: -3px !important;
  right: 0px !important;
  opacity: 1 !important;
}
.tooltip-container-location .arrow {
  display: none !important;
}
.tooltip-container-location .tooltip-inner {
  background-color: #FFFFFF;
  padding: 0;
  max-width: 100%;
}

.tooltip-inner {
  padding: 0 !important;
  border-radius: 5px !important;
  background-color: #FFF !important;
}

.locations-freight-accordion > .mat-expansion-panel:last-child .mat-expansion-panel-header .mat-expansion-indicator {
  display: none !important;
}

.mat-panel-destinations {
  overflow: inherit !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.option-step-selected {
  border: 2px solid #00dfe5 !important;
}

.ng-scrollbar-wrapper[disabled=false][dir=ltr] > .scrollbar-control[track=vertical] {
  background-color: #FFF !important;
  width: 17px !important;
}

.ng-scrollbar {
  width: 100%;
  height: 100%;
}
.ng-scrollbar .ng-scroll-content {
  height: 100%;
  display: flex;
}

.ng-scroll-offset.ng-scroll-viewport {
  width: 100%;
}

input {
  margin: 0;
}

.mat-progress-bar-fill::after {
  background-color: #02d7dc !important;
}

.mat-vertical-stepper-content {
  height: auto !important;
  visibility: visible !important;
}

.text-strong {
  font-weight: bold;
  color: #757575;
}

.title-component {
  font-size: 28px;
  color: #373737;
  margin-bottom: 1.3rem;
  font-weight: bold;
}

.subtitle-component {
  font-size: 22px;
  color: #373737;
  margin-bottom: 2%;
  font-weight: bold;
}

.separator {
  height: 1px !important;
  width: 100%;
  margin: 20px 0px;
  background: linear-gradient(90deg, #020024 0%, white 0%, #1cd9ff 0%, white 100%);
}
.separator_center {
  height: 1px !important;
  width: 100%;
  background: linear-gradient(90deg, white 0%, white 0%, #1cd9ff 51%, white 100%);
}

.title-align-form-field {
  margin-bottom: 28px;
  margin-top: 20px;
}

.stepper-tracking .mat-stepper-vertical-line::before {
  top: 0px !important;
  bottom: 0px !important;
  border-left-width: 2px !important;
}
.stepper-tracking .mat-step-header {
  padding: 3px !important;
  pointer-events: none;
}
.stepper-tracking .mat-step-header:hover {
  background-color: transparent;
}
.stepper-tracking .mat-vertical-content-container {
  margin-left: 15px !important;
}

.mat-standard-chip {
  height: auto !important;
}

.wrapper-modal .modal-dialog .modal-content {
  width: calc(100% + 130px);
  border-radius: 10px;
  box-shadow: 2px 2px 15px 0 rgba(73, 83, 86, 0.2);
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox {
  width: 25%;
  margin-right: 38px;
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox:last-child, .wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox:nth-child(3) {
  margin-right: 0px !important;
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox:last-child .mat-checkbox-layout, .wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox:nth-child(3) .mat-checkbox-layout {
  justify-items: flex-end !important;
  width: 100% !important;
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox .mat-checkbox-frame {
  border-color: #02d7dc !important;
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox .mat-checkbox-label {
  font-weight: bold;
}
.wrapper-modal .modal-dialog .modal-content .container-checks mat-checkbox .mat-checkbox-layout {
  width: 100%;
  text-align: center;
}

.mat-form-date-picker .mat-button-wrapper {
  color: initial !important;
}
.mat-form-date-picker .mat-form-field-infix {
  margin-top: -6px;
}
.mat-form-date-picker .mat-form-field-infix .mat-form-field-label span {
  margin-left: 36px;
}
.mat-form-date-picker .mat-form-field-suffix {
  left: 0px;
  position: absolute;
  top: 8px;
  width: 31px;
  font-size: 21px;
}

.error-snackbar {
  background-color: #CF000F;
  color: #FFFFFF;
}

.success-snackbar {
  background-color: #009944;
  color: #FFFFFF;
}

.error-snackbar {
  background-color: #CF000F;
  color: #FFFFFF;
}

.alert-snackbar {
  background-color: #c4a000;
  color: #FFFFFF;
}

.info-snackbar {
  background-color: #02D7DC;
  color: #133845;
}

.mat-snack-bar-container {
  font-weight: bold;
}
.mat-snack-bar-container button * {
  font-weight: bold;
}

.star {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  font-size: 9px;
  top: 3px;
}

.contaner-icon-profile {
  width: 100%;
  text-align: right;
}
.contaner-icon-profile img {
  background: #eaeaea;
  border-radius: 10px;
  padding: 12px;
  height: 73px;
}

.container-list {
  margin: 20px;
  background-color: #FFFFFF;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
  border: 1px solid rgba(219, 219, 219, 0.54);
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 576px) {
  .container-list {
    margin: 20px 0;
  }
}
.container-list .container-list-header {
  padding: 0 12px;
  border-bottom: 1px solid #f5f5f5;
  flex: auto;
}
.container-list .container-list-header .header-list-options .mat-card-actions {
  display: block;
}
.container-list .container-list-header .header-list-options .mat-card-actions button {
  width: auto;
  padding: 0 13px;
  border: none;
}
.container-list .container-list-header .header-list-options .mat-card-actions button .mat-button-wrapper {
  font-size: 15px;
  color: #495356;
  position: relative;
  z-index: 1;
}
.container-list .container-list-header .header-list-options .mat-card-actions button .mat-button-ripple {
  background: #f5f5f5;
}
.container-list .container-list-filter {
  padding-top: 5px;
  flex: auto;
}
.container-list .container-list-filter .filter-actions {
  height: min-content;
}
.container-list .container-list-filter .input-icon {
  overflow: hidden;
}
.container-list .container-list-filter .input-icon .mat-form-field-infix .icon-input {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 23px;
  background-color: #FFF;
  margin: 0;
  height: 29px;
  padding: 2px;
}
.container-list .container-list-filter .input-icon .mat-form-field-infix .icon-input img {
  width: 100%;
}
.container-list .container-list-filter .input-icon .mat-form-field-infix input {
  padding-left: 35px;
}
.container-list .container-list-filter .input-icon .mat-form-field-infix .mat-form-field-label-wrapper {
  margin-left: 25px;
}
.container-list .container-list-filter .mat-card-actions button {
  width: auto;
}
.container-list .container-list-filter .filter-item {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  color: #929292;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #CCCCCC;
}
.container-list .container-list-filter .filter-item:last-child {
  border-right: none;
}
.container-list .container-list-filter .filter-item.filter-item-active {
  color: #584796 !important;
  text-decoration: underline;
}
.container-list .container-list-body {
  flex: auto;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.list-select-all {
  display: flex;
  justify-content: flex-end;
}
.list-select-all .check-all-title {
  padding: 0 15px;
  margin: 5px 0;
  text-align: right;
  font-weight: bold;
  cursor: pointer;
  color: #676767;
}
@media only screen and (max-width: 768px) {
  .list-select-all .check-all-title {
    display: contents;
  }
}

.item-list-selected {
  background-color: #c4c4c4 !important;
}

.item__list {
  border-radius: 10px;
  box-shadow: 0px 2px 3px #a2a2a252;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  gap: 15px;
}
.item__list--col {
  width: 100%;
}
.item__list--col label {
  display: block;
  font-weight: bold;
  color: #584796;
}
.item__list--col span {
  display: block;
  color: #495356;
}

#containerUserInactive {
  position: fixed;
  top: 72px;
  z-index: 2;
  left: 240px;
  width: calc(100% - 257px);
  box-shadow: -1px 5px 8px #495356;
  height: 78px;
  background-color: #E2FCFD;
  text-align: center;
  font-weight: bold;
  color: #584796;
  font-size: 22px;
  line-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 25px;
}
#containerUserInactive span {
  margin: auto;
}

.nav-vertical .hide-expansion-panel .header-nav-parent .mat-expansion-indicator {
  display: none;
}

.mat-form-date-picker input {
  padding-left: 31px;
}

#mainCreateCargoFromGuides .mat-tab-header {
  margin-bottom: 20px;
}

.min-h-100-margin {
  min-height: calc(100% - 40px) !important;
}

.h-100-margin {
  height: calc(100% - 20px) !important;
}

.w-100 {
  width: 100%;
}

#mainUploadGuides .mat-tab-body-wrapper {
  background-color: #FFFFFF;
  border-radius: 0px 10px 10px 10px;
  padding: 18px;
}

textarea,
input {
  outline: none !important;
}

.otp-input {
  border-radius: 10px !important;
  background-color: #FFFFFF !important;
  box-shadow: 2px 2px 15px 0 rgba(73, 83, 86, 0.2) !important;
  height: 60px !important;
  font-size: 25px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 58px !important;
  font-weight: bold;
  text-align: center;
  border-bottom: 0px solid transparent !important;
}

ng-otp-input .wrapper {
  text-align: center;
}

.width-100 {
  width: 100%;
}

.margin-auto {
  margin: auto;
}

i.fas,
i.far {
  padding: 4px;
}

.font-primary-dark {
  color: #495356 !important;
}

.font-primary-light {
  color: #02d7dc !important;
}

.font-secondary-dark {
  color: #584796 !important;
}

.pac-container {
  z-index: 1052 !important;
}

.field__form {
  position: relative;
}
.field__form--icon {
  position: absolute;
  right: 0;
  bottom: 3px;
}
.field__form--input {
  padding-right: 25px !important;
}

.spinner-input {
  position: absolute;
  right: 0;
}

.pattern {
  font-size: 12px;
  bottom: -16px;
  position: absolute;
  font-weight: bold;
  width: 100%;
  display: block;
  text-align: center;
  letter-spacing: 0.4px;
}
.pattern--top {
  top: -3px !important;
  font-size: 16px;
}
.pattern--info {
  color: #02d7dc;
}
.pattern--invalid {
  color: #f44336;
}
.pattern--warning {
  color: #ffc107 !important;
}
.pattern--autocomplete {
  bottom: -1px !important;
}

.text__color.color--error {
  color: #f44336 !important;
}
.text__color.color--success {
  color: #009944 !important;
}
.text__color.color--warning {
  color: #ffc107 !important;
}

.color--error {
  color: #f44336 !important;
}
.color--success {
  color: #009944 !important;
}
.color--warning {
  color: #ffc107 !important;
}

.text-underlined {
  cursor: pointer;
  text-decoration: underline !important;
}

.asterisk {
  color: #f44336 !important;
  font-weight: 500;
}

.optional {
  color: #C2BECA;
  font-weight: 100;
  margin-left: 5px;
}

.field__card {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.field__card--label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.field__card--value {
  color: #495356;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

.z__index--1 {
  z-index: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.absolute--right-top {
  top: 0;
  right: 2%;
}
.absolute--right-bottom {
  top: 1.5rem;
  right: 0;
}
.absolute--right-bottom {
  top: 0rem;
  right: 1.5rem;
}

.spinner-input {
  animation: rotation 2s infinite linear;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.company-select {
  padding-top: 9px;
}

.filter--company {
  margin-top: -13px;
}

app-cost-center-list {
  display: block;
  height: 100%;
}

.wrapper__article {
  height: calc(100% - 40px);
  margin: 20px;
  background-color: #FFFFFF;
  padding: 4px 4px 30px 4px;
  border-radius: 10px;
  border: 1px solid rgba(219, 219, 219, 0.54);
  overflow: hidden;
}
@media only screen and (max-width: 400px) {
  .wrapper__article {
    margin: 20px 0;
  }
}
.wrapper__article__title {
  padding: 20px;
}
.wrapper__article__title h5 {
  font-weight: bold;
  color: #495356;
}
.wrapper__article__body {
  overflow-y: auto;
  height: calc(100% - 76px);
  position: relative;
}
.wrapper__article__body--tab {
  height: calc(100% - 76px);
  position: relative;
  overflow: hidden;
}
.wrapper__article__body--tab .mat-tab-group {
  height: 100%;
  overflow: hidden;
}
.wrapper__article__body--tab .mat-tab-group .mat-tab-body-content {
  height: 100%;
  overflow: hidden;
}
.wrapper__article__content {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.wrapper__article__content .mat-tab-group {
  height: 100%;
  overflow: hidden;
}
.wrapper__article__content .mat-tab-group .mat-tab-body-content {
  height: 100%;
  overflow: hidden;
}
.wrapper__article__form {
  height: 100%;
}
.wrapper__article__form--content {
  height: calc(100% - 66px);
  padding: 10px;
}
.wrapper__article__form--footer .mat-card-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.icon__bill {
  height: 25px;
  position: absolute;
  left: -33px;
  top: -5px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  margin: 3px;
  display: flex;
  align-items: center;
}
.icon__bill img {
  width: 100%;
  margin-bottom: 2px;
}

carousel .carousel-cell img {
  object-fit: scale-down !important;
}

.cancel-value-select {
  position: relative;
  top: -10px;
  right: 6px;
  font-weight: bold;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.container-flex {
  display: flex !important;
}
.container-flex_row {
  display: flex;
  flex-direction: row !important;
}
.container-flex_col {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.container-flex_center {
  justify-content: center;
  align-items: center;
}

.txt_center {
  text-align: center;
}
.txt_left {
  text-align: left;
}
.txt_right {
  text-align: right;
}

.aling-conten_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aling-conten_start-up {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aling-conten_start-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.aling-conten_end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.border-intermitente-card {
  animation: border 10s ease-out infinite;
}

@keyframes border {
  50% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
}
.bell {
  -webkit-animation: tiembla 0.3s infinite;
}

@-webkit-keyframes tiembla {
  0% {
    -webkit-transform: rotateZ(-5deg);
  }
  50% {
    -webkit-transform: rotateZ(0deg) scale(0.8);
  }
  100% {
    -webkit-transform: rotateZ(5deg);
  }
}
.message {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.message--error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.message--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.message--warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.message--info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.display-none {
  display: none !important;
}

.close-icon-actions {
  cursor: pointer;
  color: #6a7376;
  position: absolute;
  right: 0.5em;
  top: 0.8em;
  z-index: 2;
  font-size: 28px;
}
.close-icon-actions:hover {
  color: #02d7dc;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  background-color: #FFFFFF !important;
  padding: 0 !important;
  padding-left: 12px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
}

.clean-filter-icon {
  position: absolute;
  top: 42%;
  right: 2px;
  /* Ajusta la posición según lo necesario */
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 6px;
  color: #666666;
  margin-right: 8px;
  font-size: 18px;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-input-element {
  padding-right: 34px !important;
}

.no-permission-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.no-permission-container i {
  color: #584796;
  transform: scale(1.5);
}
.no-permission-container h4 {
  text-wrap: balance;
}

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.button-container button {
  margin-top: 0.5rem !important;
}

.conv-btn {
  padding: 2px 5px;
  border: 2px solid white;
  font-weight: bolder;
  border-radius: 10px;
  color: black;
  cursor: pointer;
}
.conv-btn:hover {
  color: #02d7dc;
}
.conv-btn i {
  animation: rainbow 4s infinite linear;
}

@keyframes rainbow {
  0% {
    color: yellow;
  }
  50% {
    color: red;
  }
  100% {
    color: yellow;
  }
}
.mat-card-header-text {
  margin: 0 !important;
}

mat-error {
  font-weight: bold;
}

.custom-mat-error {
  transform: translateY(-15px);
  font-size: 12px;
  font-weight: bold;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  background-color: #FFFFFF !important;
  padding-right: 8px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-invalid {
  color: #f44336 !important;
}

.mat-simple-snackbar-action {
  border-radius: 10px;
  color: #495356 !important;
}

.mat-simple-snackbar-action:hover {
  color: white !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8ee7e9 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8ee7e9;
  border-radius: 0px;
  border: 3px solid #8ee7e9;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  align-items: center;
}

app-preview-notification {
  display: inline-block;
}

.custom-tooltip {
  white-space: pre-line;
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.disabled-btn {
  background-color: #8f8f90 !important;
  color: #c6c6c6 !important;
}
.disabled-btn span {
  color: #c6c6c6 !important;
}

.secondary-button-t-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: bold;
}
.secondary-button-t-group .button-t {
  max-width: 100%;
  background-color: white;
  color: #02d7dc;
  border-radius: 10px;
  text-align: center;
  padding: 2px 40px;
  height: auto;
  cursor: pointer;
  border: 2px solid #02d7dc;
}
@media only screen and (min-width: 1200px) {
  .secondary-button-t-group .button-t {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 420px) {
  .secondary-button-t-group .button-t {
    width: 100%;
  }
}
.secondary-button-t-group .button-t.selected {
  color: white;
  background-color: #02d7dc;
}

/* Estilos para toggle personalizado */
.custom-slide-toggle {
  position: relative;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.custom-slide-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-slide-toggle input:checked + .slider {
  background-color: #00e2d1;
}
.custom-slide-toggle input:checked + .slider:before {
  transform: translateX(26px);
}
.custom-slide-toggle__toggle-label {
  font-size: 16px;
  color: #495356;
}

.slider {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 12px;
  transition: background-color 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

.read-only-stepper .mat-step-header {
  pointer-events: none;
  /* Desactiva clics en los tabs superiores */
  cursor: default;
}

.wrap-checkbox .mat-checkbox-label {
  white-space: normal !important;
  word-break: break-word;
  display: block;
}

.danger-text {
  color: #DC3545;
}

::ng-deep mat-option {
  position: relative;
  overflow: visible;
}

::ng-deep mat-option::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, 10px);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

::ng-deep mat-option:hover::after {
  opacity: 1;
  transform: translate(-50%, 0);
}

.mat-suffix-style .mat-form-field-suffix {
  display: flex;
}