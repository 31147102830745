@font-face {
    font-family: 'Nunito Bold Italic';
    src: url('/assets/fonts/Nunito/nunito_bold_italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Bold';
    src: url('/assets/fonts/Nunito/nunito_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Italic';
    src: url('/assets/fonts/Nunito/nunito_italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito Regular';
    src: url('/assets/fonts/Nunito/nunito_regular.ttf') format('truetype');
}