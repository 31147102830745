@import "./styles.enviroments.scss";
@import "./mixins.scss";

.mat-step-header .mat-step-icon {
    background: $secondary-color-dark !important;
    color: white !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background: $primary-color-light !important;
    color: white !important;
}

.container-input-date-picker {
    font-size: 15px;
    margin-top: 5px;

    input {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.mat-calendar-body {
    .mat-calendar-body-selected {
        background-color: $primary-color-light;
        font-weight: bold;
        color: #FFF;
    }
}

.cdk-overlay-container {
    z-index: 1051 !important;
}

.mat-datetimepicker-calendar-header {
    background-color: $primary-color-light;
    color: #FFF;
}

.mat-input-element[type="time"] {
    position: relative;

    &::after {
        content: none !important;
    }

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 20px !important;
        height: 20px !important;
        right: 10px;
        color: (0, 0, 0, 0.54) !important;
        cursor: pointer !important;
    }

    .mat-input-element {
        padding-right: 0 !important;
    }
}


.mat-input-element {
    box-shadow: none !important;
    outline: none !important;
}

.mat-expansion-panel {
    margin: 0 !important;
}

.mat-card-actions {
    margin-top: 20px;
    width: 100%;
}

.mat-step-label {
    width: 100%;
}

.stepper-tracking {
    .mat-vertical-content {
        padding: 0 2px 24px 24px;
    }
}

app-download-location {
    .mat-content {
        position: relative !important;
    }
}

.tooltip__alert {
    font-size: 14px;
    max-width: 320px !important;
    background-color: $secondary-color-dark !important;
    text-align: center;
}

.tooltip__error {
    font-size: 14px;
    max-width: 320px !important;
    background-color: rgb(231, 33, 33) !important;
    text-align: center;
}

.mat-card {
    padding: 15px !important;
}


.mat-form-field-check {
    padding-top: 40px;
}

.mat-expansion-panel-header-description-right {
    text-align: revert;
    font-size: 12px;
    font-weight: bold;
    flex-direction: row-reverse;
}

.mat-expansion-panel-header-description-left {
    text-align: start;
    font-size: 12px;
    font-weight: bold;
}

app-confirm-ministry {
    .mat-button-toggle-label-content {
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 24px;
    }

    .mat-button-toggle {
        width: 50%;
    }

    .mat-button-toggle-group {
        width: 100%;
        height: 60px !important;
    }

}

.cargo__options--menu {
    .mat-menu-item {
        font-size: 13px;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e9e9e9;

        &:hover {
            background-color: #d9d8d8 !important;
            font-weight: bold;
            color: #495356;
        }
    }
}

.stepper-form-vehicle {
    .mat-horizontal-stepper-header {
        padding: 0 24px !important;

        .mat-step-label {
            padding-top: 4px !important;
        }

        .mat-step-optional {
            display: none;
        }

        &::before,
        &::after {
            border: none !important;
        }
    }
}

app-contact-person-vehicle,
app-gps-vehicle,
.form__vehicle_documents {
    .mat-form-field-flex {
        padding: 0 !important;
    }
}


.routing__form {
    .mat-horizontal-stepper-header-container {
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .mat-horizontal-content-container {
        background: #fff;
        border-radius: 10px;
        height: calc(100% - 130px);
        padding: 30px;

        @include extrasmall {
            padding-inline: 10px;
        }

        .mat-horizontal-stepper-content {
            //height: 100% !important;
        }
    }
}

.fleet__filter {
    .mat-form-field-wrapper {
        margin: 0;
        border: 0;
        padding: 0;
    }

    .mat-form-field-infix {
        padding: 10px 0 !important;
        border: 0 !important;
    }
}

.fleet__actions_body {
    &.mat-card-actions {
        padding: 0px 16px 16px 16px;
        display: block;
        margin: 0;
    }
}

.vehicle-transport {
    .mat-button-wrapper {
        font-size: 1em !important;
    }
}

.cargo {
    &__header {
        &--actions {
            justify-content: end;
            gap: 10px;

            button {
                height: 30px !important;
                line-height: 0 !important;

                .mat-button-wrapper {
                    font-size: 14px !important;
                }
            }
        }
    }
}